const Term = () => {  
  return (
    <div className='flex flex-col bg-slate-100 w-full h-screen lg:text-[1.5rem] text-[1rem] transaction-height duration-75 ease-out'>
      
      Term
    </div>
  )
}

export default Term;
